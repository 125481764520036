.ng-select {
    padding-bottom: 1.25em
}

.ng-select.ng-select-disabled .ng-select-container:after {
    border-bottom-color: transparent;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
    background-size: 4px 1px;
    background-repeat: repeat-x
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    color: rgba(0, 0, 0, 0.38)
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder {
    color: rgba(0, 0, 0, 0.38)
}

.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-disabled .ng-clear-wrapper {
    color: rgba(0, 0, 0, 0.38)
}

.ng-select.ng-select-focused .ng-select-container:after {
    border-color: #FF8703;
    border-width: 2px
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
    border-color: #FF8703;
    border-width: 2px
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    color: #FF8703
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: #FF8703
}

.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-filtered .ng-select-container .ng-placeholder {
    display: initial
}

.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px)
}

.ng-select .ng-select-container {
    color: rgba(0, 0, 0, 0.87);
    align-items: baseline;
    min-height: 51.5px
}

.ng-select .ng-select-container:after {
    border-bottom: thin solid rgb(255,135,3);
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    transition: border-color 0.3s rgb(255,135,3);
    display: none;
    
}

.ng-select .ng-select-container.ng-appearance-outline {
    padding: 0 .5em;
    min-height: 60px
}

.ng-select .ng-select-container.ng-appearance-outline:after {
    border: solid 1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    height: calc(100% - .5em);
    pointer-events: none;
    transition: border-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)
}

.ng-select .ng-select-container.ng-appearance-outline:hover:after {
    border-color: rgba(0, 0, 0, 0.87);
    border-width: 2px
}

.ng-select .ng-select-container.ng-appearance-outline .ng-placeholder {
    padding: 0 .25em;
    background-color: #fff;
    z-index: 1
}

.ng-select .ng-select-container.ng-appearance-outline .ng-value {
    padding-left: .25em
}

.ng-select .ng-select-container .ng-value-container {
    align-items: stretch;
    padding: .4375em 0;
    border-top: .84375em solid transparent
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    position: absolute;
    color: rgba(0, 0, 0, 0.54);
    transform-origin: left 0;
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)
}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    transform-origin: right 0
}

.ng-select .ng-select-container .ng-value-container .ng-input {
    bottom: .4375em
}

.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
    bottom: 17px
}

.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-clear-wrapper {
    bottom: 14px
}

.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: flex-end;
    bottom: 9px
}

.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    align-self: flex-end;
    bottom: 7px
}

.ng-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26)
}

.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:after,
.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:hover:after {
    background-image: none;
    border: dotted 1px rgba(0, 0, 0, 0.12)
}

.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline.ng-has-value .ng-arrow-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline.ng-has-value .ng-clear-wrapper {
    border-top: none
}

.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
    top: 0
}

.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline .ng-clear-wrapper {
    top: 4px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #FF8703;
    border-radius: 25px;
    color: #fff;
    padding: 5px 8px;
    margin: 0 0.4375em 0.4375em 0
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin: 0 0 0.4375em 0.4375em
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26)
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    font-size: 14px;
    font-weight: 500;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    padding-right: 5px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    padding-left: 5px;
    padding-right: 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    color: rgba(255, 255, 255, 0.87)
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    line-height: 1.375em
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value {
    align-items: center
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
    padding-bottom: 0;
    padding-top: .1875em
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
    border-top: .84375em solid transparent
}

.ng-select .ng-clear-wrapper {
    color: rgba(0, 0, 0, 0.54)
}

.ng-select .ng-clear-wrapper:hover {
    color: rgba(0, 0, 0, 0.87)
}

.ng-select .ng-arrow-wrapper {
    bottom: 2px
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 4px;
    color: rgba(0, 0, 0, 0.54)
}

.ng-select .ng-spinner-zone {
    top: 3px
}

.ng-dropdown-panel {
    background: #fff;
    left: 0
}

.ng-dropdown-panel.ng-select-top {
    bottom: calc(100% - .84375em);
    box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.2), 0 -8px 10px 1px rgba(0, 0, 0, 0.14), 0 -3px 14px 2px rgba(0, 0, 0, 0.12)
}

.ng-dropdown-panel.ng-select-right {
    left: 100%;
    top: calc(0% + .84375em);
    box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.2), 0 -8px 10px 1px rgba(0, 0, 0, 0.14), 0 -3px 14px 2px rgba(0, 0, 0, 0.12);
    margin-left: 4px
}

.ng-dropdown-panel.ng-select-bottom {
    top: calc(100% - 1.25em);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.ng-dropdown-panel.ng-select-left {
    left: calc(-100% - 4px);
    top: calc(0% + .84375em);
    box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.2), 0 -8px 10px 1px rgba(0, 0, 0, 0.14), 0 -3px 14px 2px rgba(0, 0, 0, 0.12)
}

.ng-dropdown-panel.multiple .ng-option.selected {
    background: #fff
}

.ng-dropdown-panel.multiple .ng-option.marked {
    background: rgba(0, 0, 0, 0.04)
}

.ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em
}

.ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    user-select: none;
    cursor: pointer;
    line-height: 3em;
    height: 3em;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    background: rgba(0, 0, 0, 0.04)
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
    background: rgba(0, 0, 0, 0.12);
    color: #FF8703
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    line-height: 3em;
    min-height: 3em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
    text-decoration: none;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    text-align: left
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    text-align: right
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background: rgba(255,135,3,0.3);
    color: rgb(255,135,3)
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background: rgba(255,135,3,0.1);
    color: #FF8703
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: rgba(0, 0, 0, 0.38)
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: 32px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-right: 32px;
    padding-left: 0
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-right: 5px;
    font-size: 80%;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.38)
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-left: 5px;
    padding-right: 0
}
