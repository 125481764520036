.ng-select.ng-select-opened>.ng-select-container {
    background: #fff;
    border-color: #b3b3b3 #ccc #d9d9d9
}

.ng-select.ng-select-opened>.ng-select-container:hover {
    box-shadow: none
}


.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: -2px;
    border-color: transparent transparent #999;
    border-width: 0 5px 5px
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover {
    border-color: transparent transparent #333
}

.ng-select.ng-select-opened.ng-select-top>.ng-select-container {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.ng-select.ng-select-opened.ng-select-right>.ng-select-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-radius: 25px;
    /* border-bottom: 1px solid #FF8703;
    border-top: none;
    border-left: none;
    border-right: none; */
    border-color: transparent;
    box-shadow:inset -7px -63px 0px -61.5px #ff8703;

    transition: box-shadow  0.3s ease-in-out ;

}

.ng-select.ng-select-opened.ng-select-left>.ng-select-container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)
}

.ng-select.ng-select-disabled>.ng-select-container {
    background-color: #f9f9f9
}

.ng-select .ng-has-value .ng-placeholder {
    display: none
}

.ng-select .ng-select-container {
    color: #333;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #FF8703;
    min-height: 36px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}



.ng-select .ng-select-container:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    background-color: rgb(255,135,3,0.1);
}


.ng-select .ng-select-container:focus-within {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.ng-select .ng-select-container .ng-value-container {
    align-items: center;
    padding-left: 10px
}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container {
    padding-right: 10px;
    padding-left: 0
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #FF8703
}

.ng-select.ng-select-single .ng-select-container {
    height: 36px
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 5px;
    left: 0;
    padding-left: 10px;
    padding-right: 50px
}

[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-right: 10px;
    padding-left: 50px
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
    background-color: #f9f9f9;
    border: 1px solid #e6e6e6
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
    padding: 0 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 5px;
    padding-left: 7px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-right: 7px;
    padding-left: 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: .9em;
    margin-bottom: 5px;
    color: #333;
    background-color: #ebf5ff;
    border-radius: 2px;
    margin-right: 5px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin-right: 0;
    margin-left: 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
    background-color: #f9f9f9
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
    padding-left: 5px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
    padding-left: 0;
    padding-right: 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    display: inline-block;
    padding: 1px 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: inline-block;
    padding: 1px 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: red;
    border-radius:25px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid white
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-left: 1px solid white;
    border-right: none
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 1px solid white
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 0;
    border-right: 1px solid white
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding: 0 0 3px 3px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding: 0 3px 3px 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
    color: #000
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 5px;
    padding-bottom: 5px;
    padding-left: 3px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    padding-right: 3px;
    padding-left: 0
}

.ng-select .ng-clear-wrapper {
    color: #999
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
    color: #D0021B
}

.ng-select .ng-spinner-zone {
    padding: 5px 5px 0 0
}

[dir="rtl"] .ng-select .ng-spinner-zone {
    padding: 5px 0 0 5px
}

.ng-select .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px
}

[dir="rtl"] .ng-select .ng-arrow-wrapper {
    padding-left: 5px;
    padding-right: 0
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
    border-top-color: #666
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px
}

.ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0
}

.ng-dropdown-panel.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

.ng-dropdown-panel.ng-select-right {
    left: 100%;
    top: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 4px
}

.ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: #e6e6e6;
    margin-top: -1px
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.ng-dropdown-panel.ng-select-left {
    left: -100%;
    top: 0;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child {
    border-top-left-radius: 4px
}

.ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid #ccc;
    padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid #ccc;
    padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    user-select: none;
    padding: 8px 10px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    background-color: #f5faff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
    color: rgba(0, 0, 0, 0.54);
    background-color: #ebf5ff;
    font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 8px 10px
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: rgb(255,135,3);
    background-color: rgb(255,135,3,0.3)
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #f5faff;
    color: #333
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: #ccc
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: 22px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-right: 22px;
    padding-left: 0
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    font-size: 80%;
    font-weight: 400;
    padding-right: 5px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-left: 5px;
    padding-right: 0
}

[dir="rtl"] .ng-dropdown-panel {
    direction: rtl;
    text-align: right
}
